import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { ENVIRONMENT_SLUG } from './globals/vite-constants';

Sentry.init({
	dsn: 'https://770472a903d147fabf2b28d2d124101e@o532657.ingest.sentry.io/6265253',
	integrations: [new BrowserTracing()],
	environment: import.meta.env.DEV ? 'local' : ENVIRONMENT_SLUG ?? 'server',
	enabled: !import.meta.env.DEV || import.meta.env.VITE_SENTRY_ENABLED === 'true',

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// TODO: adjust this value in production
	tracesSampleRate: 1.0,
});

export { Sentry };

export default Sentry;
